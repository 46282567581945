@import "https://p.typekit.net/p.css?s=1&k=sku8ffl&ht=tk&f=24537.24538.24539.24540.24541.24542.24543.24544.24545.24546.24547.24548.24549.24550.24551.24552&a=20688374&app=typekit&e=css";
*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  /*
    While not totally recommended due to browser support, these font-smoothing settings are used
    widely at sites such as vercel.com to ensure fonts look the same in design as they do in
    the final implementation
  */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-weight: inherit;
}
ul,
ol {
  margin: 0;
  padding-left: 1.5rem;
}
button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
a {
  color: inherit;
  text-decoration: none;
}
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body *:focus,
body *:focus-visible {
  outline: none;
}
legend {
  display: table;
  padding: 0;
}
fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0.01em 0 0;
}
body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}
iframe {
  border: none;
}
input,
textarea,
[role="textbox"] {
  cursor: text;
}
:export {
  white: #ffffff;
  black: #020617;
  brand: #2397c8;
  transparent: rgba(255, 255, 255, 0);
  highlight: #f9be89;
  grey-10: #fdfefe;
  grey-25: #fbfcfd;
  grey-50: #f8fafc;
  grey-75: #f5f8fb;
  grey-100: #f1f5f9;
  grey-150: #ebeff5;
  grey-200: #e2e8f0;
  grey-300: #cbd5e1;
  grey-400: #94a3b8;
  grey-500: #64748b;
  grey-600: #475569;
  grey-700: #334155;
  grey-800: #1e293b;
  grey-900: #0f172a;
  grey-950: #020617;
  success-25: #f7fef9;
  success-50: #f0fdf4;
  success-75: #e7fdee;
  success-100: #dcfce7;
  success-150: #cff9de;
  success-200: #bbf7d0;
  success-300: #86efac;
  success-400: #4ade80;
  success-500: #22c55e;
  success-600: #16a34a;
  success-700: #15803d;
  success-800: #166534;
  success-900: #14532d;
  warning-25: #fffdf5;
  warning-50: #fffbeb;
  warning-75: #fef7d8;
  warning-100: #fef3c7;
  warning-150: #feeeae;
  warning-200: #fde68a;
  warning-300: #fcd34d;
  warning-400: #fbbf24;
  warning-500: #f59e0b;
  warning-600: #d97706;
  warning-700: #b45309;
  warning-800: #92400e;
  warning-900: #78350f;
  danger-25: #fef8f8;
  danger-50: #fef2f2;
  danger-75: #ffebeb;
  danger-100: #fee2e2;
  danger-150: #fedada;
  danger-200: #fecaca;
  danger-300: #fca5a5;
  danger-400: #f87171;
  danger-500: #ef4444;
  danger-600: #dc2626;
  danger-700: #b91c1c;
  danger-800: #991b1b;
  danger-900: #7f1d1d;
  accent-25: #f6f8ff;
  accent-50: #eef2ff;
  accent-75: #e9eeff;
  accent-100: #e0e7ff;
  accent-150: #d8e0fe;
  accent-200: #c7d2fe;
  accent-300: #a5b4fc;
  accent-400: #818cf8;
  accent-500: #6366f1;
  accent-600: #4f46e5;
  accent-700: #4338ca;
  accent-800: #3730a3;
  accent-900: #312e81;
  promotion-25: #f7fefc;
  promotion-50: #f0fdfa;
  promotion-75: #dbfcf5;
  promotion-100: #ccfbf1;
  promotion-150: #b8f9ec;
  promotion-200: #99f6e4;
  promotion-300: #5eead4;
  promotion-400: #2dd4bf;
  promotion-500: #14b8a6;
  promotion-600: #0d9488;
  promotion-700: #0f766e;
  promotion-800: #115e59;
  promotion-900: #134e4a;
}
/* prettier-ignore */
/* prettier-ignore */
/* prettier-ignore */
/* prettier-ignore */
/* prettier-ignore */
/* stylelint-disable at-rule-no-unknown */
/*
  Stylelint doesn't like this syntax, but it's perfectly fine for less.
  See https://lesscss.org/features/#plugin-atrules-feature
 */
/* prettier-ignore */
@font-face {
  font-family: sofia-pro;
  src: url("https://use.typekit.net/af/773a22/00000000000000007735f996/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3") format("woff2"), url("https://use.typekit.net/af/773a22/00000000000000007735f996/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3") format("woff"), url("https://use.typekit.net/af/773a22/00000000000000007735f996/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  descent-override: 45%;
}
@font-face {
  font-family: sofia-pro;
  src: url("https://use.typekit.net/af/2cf3cc/00000000000000007735f991/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i9&v=3") format("woff2"), url("https://use.typekit.net/af/2cf3cc/00000000000000007735f991/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i9&v=3") format("woff"), url("https://use.typekit.net/af/2cf3cc/00000000000000007735f991/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i9&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
  descent-override: 45%;
}
@font-face {
  font-family: sofia-pro;
  src: url("https://use.typekit.net/af/5d97ff/00000000000000007735f999/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/5d97ff/00000000000000007735f999/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/5d97ff/00000000000000007735f999/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  descent-override: 45%;
}
@font-face {
  font-family: sofia-pro;
  src: url("https://use.typekit.net/af/66e20c/00000000000000007735f993/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/66e20c/00000000000000007735f993/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/66e20c/00000000000000007735f993/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
  descent-override: 45%;
}
@font-face {
  font-family: sofia-pro;
  src: url("https://use.typekit.net/af/bb213c/00000000000000007735f995/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3") format("woff2"), url("https://use.typekit.net/af/bb213c/00000000000000007735f995/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3") format("woff"), url("https://use.typekit.net/af/bb213c/00000000000000007735f995/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 200;
  font-stretch: normal;
  descent-override: 45%;
}
@font-face {
  font-family: sofia-pro;
  src: url("https://use.typekit.net/af/ceaf1c/00000000000000007735f98f/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i2&v=3") format("woff2"), url("https://use.typekit.net/af/ceaf1c/00000000000000007735f98f/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i2&v=3") format("woff"), url("https://use.typekit.net/af/ceaf1c/00000000000000007735f98f/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i2&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 200;
  font-stretch: normal;
  descent-override: 45%;
}
@font-face {
  font-family: sofia-pro;
  src: url("https://use.typekit.net/af/2e2409/00000000000000007735f990/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/2e2409/00000000000000007735f990/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/2e2409/00000000000000007735f990/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  descent-override: 45%;
}
@font-face {
  font-family: sofia-pro;
  src: url("https://use.typekit.net/af/0ab956/00000000000000007735f998/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i3&v=3") format("woff2"), url("https://use.typekit.net/af/0ab956/00000000000000007735f998/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i3&v=3") format("woff"), url("https://use.typekit.net/af/0ab956/00000000000000007735f998/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i3&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
  descent-override: 45%;
}
@font-face {
  font-family: sofia-pro;
  src: url("https://use.typekit.net/af/19ced7/00000000000000007735f992/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/19ced7/00000000000000007735f992/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/19ced7/00000000000000007735f992/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  descent-override: 45%;
}
@font-face {
  font-family: sofia-pro;
  src: url("https://use.typekit.net/af/33e8a4/00000000000000007735f99b/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i5&v=3") format("woff2"), url("https://use.typekit.net/af/33e8a4/00000000000000007735f99b/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i5&v=3") format("woff"), url("https://use.typekit.net/af/33e8a4/00000000000000007735f99b/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i5&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
  descent-override: 45%;
}
@font-face {
  font-family: sofia-pro;
  src: url("https://use.typekit.net/af/b718ff/00000000000000007735f98d/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/b718ff/00000000000000007735f98d/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/b718ff/00000000000000007735f98d/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  descent-override: 45%;
}
@font-face {
  font-family: sofia-pro;
  src: url("https://use.typekit.net/af/c09857/00000000000000007735f98c/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/c09857/00000000000000007735f98c/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/c09857/00000000000000007735f98c/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  descent-override: 45%;
}
@font-face {
  font-family: sofia-pro;
  src: url("https://use.typekit.net/af/262452/00000000000000007735f99a/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/262452/00000000000000007735f99a/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/262452/00000000000000007735f99a/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  descent-override: 45%;
}
@font-face {
  font-family: sofia-pro;
  src: url("https://use.typekit.net/af/2dec1e/00000000000000007735f997/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n1&v=3") format("woff2"), url("https://use.typekit.net/af/2dec1e/00000000000000007735f997/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n1&v=3") format("woff"), url("https://use.typekit.net/af/2dec1e/00000000000000007735f997/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n1&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 100;
  font-stretch: normal;
  descent-override: 45%;
}
@font-face {
  font-family: sofia-pro;
  src: url("https://use.typekit.net/af/ed1bc0/00000000000000007735f98e/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i1&v=3") format("woff2"), url("https://use.typekit.net/af/ed1bc0/00000000000000007735f98e/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i1&v=3") format("woff"), url("https://use.typekit.net/af/ed1bc0/00000000000000007735f98e/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i1&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 100;
  font-stretch: normal;
  descent-override: 45%;
}
@font-face {
  font-family: sofia-pro;
  src: url("https://use.typekit.net/af/36f509/00000000000000007735f994/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i6&v=3") format("woff2"), url("https://use.typekit.net/af/36f509/00000000000000007735f994/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i6&v=3") format("woff"), url("https://use.typekit.net/af/36f509/00000000000000007735f994/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i6&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 600;
  font-stretch: normal;
  descent-override: 45%;
}
@font-face {
  font-family: "Reenie Beanie";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/reeniebeanie/v16/z7NSdR76eDkaJKZJFkkjuvWxXPq1q6Gjb_0.woff2") format("woff2");
  /* stylelint-disable-next-line max-line-length */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Azeret Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/azeretmono/v17/3XFuErsiyJsY9O_Gepph-HHvZfn23vRKV0U.woff2") format("woff2");
  /* stylelint-disable-next-line max-line-length */
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Azeret Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/azeretmono/v17/3XFuErsiyJsY9O_Gepph-HHhZfn23vRK.woff2") format("woff2");
  /* stylelint-disable-next-line max-line-length */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Azeret Mono";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/azeretmono/v17/3XFuErsiyJsY9O_Gepph-HHvZfn23vRKV0U.woff2") format("woff2");
  /* stylelint-disable-next-line max-line-length */
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Azeret Mono";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/azeretmono/v17/3XFuErsiyJsY9O_Gepph-HHhZfn23vRK.woff2") format("woff2");
  /* stylelint-disable-next-line max-line-length */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Azeret Mono";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/azeretmono/v17/3XFuErsiyJsY9O_Gepph-HHvZfn23vRKV0U.woff2") format("woff2");
  /* stylelint-disable-next-line max-line-length */
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Azeret Mono";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/azeretmono/v17/3XFuErsiyJsY9O_Gepph-HHhZfn23vRK.woff2") format("woff2");
  /* stylelint-disable-next-line max-line-length */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.clinic_login {
  background: #f8fafc;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.clinic_login__content {
  position: relative;
  z-index: 1;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 56px 24px;
}
.clinic_login__lines {
  inset: 50% 0 0;
  position: absolute;
  z-index: 0;
  width: 100%;
  background-image: url("../img/pharmacy/backgrounds/lines-desktop.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.clinic_login__logo {
  width: 172px;
  margin-bottom: 24px;
}
.clinic_login__image {
  max-width: 100%;
  max-height: 100%;
}
.clinic_login__box {
  border: 1px solid #ebeff5;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(51, 65, 85, 0.1), 0 1px 2px -1px rgba(51, 65, 85, 0.1);
  background-color: #ffffff;
  padding: 42px 60px;
  width: 100%;
  max-width: 540px;
}
.clinic_login__title {
  font-family: "sofia-pro", sans-serif;
  font-size: 24px;
  line-height: 1.33;
  font-weight: 600;
  color: #1e293b;
  text-align: center;
  margin-bottom: 32px;
}
@media only screen and (max-width: 500px) {
  .clinic_login__title {
    font-size: 20px;
  }
}
.clinic_login__input-group {
  margin-bottom: 24px;
  display: flex;
}
.clinic_login__label-group {
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
}
.clinic_login__label {
  font-family: "sofia-pro", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  font-weight: 500;
  color: #475569;
}
@media only screen and (max-width: 500px) {
  .clinic_login__label {
    font-size: 14px;
  }
}
.clinic_login__help {
  font-family: "sofia-pro", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  font-weight: 400;
  color: #1e293b;
}
@media only screen and (max-width: 500px) {
  .clinic_login__help {
    font-size: 14px;
  }
}
.clinic_login__input {
  font-family: "sofia-pro", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  transition-duration: 20ms;
  transition-timing-function: ease-in-out;
  transition-property: border;
  color: #1e293b;
  display: block;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: #ffffff;
  transition: border 0.1s ease-in-out;
}
@media only screen and (max-width: 500px) {
  .clinic_login__input {
    font-size: 14px;
  }
}
.clinic_login__input:hover {
  border: 1px solid #cbd5e1;
}
.clinic_login__input:focus {
  border-color: #334155;
  box-shadow: inset 0 0 0 0.5px #334155;
  transition: box-shadow 20ms;
  outline: 1.5px dotted transparent;
}
.clinic_login__button {
  font-family: "sofia-pro", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  transition-duration: 20ms;
  transition-timing-function: ease-in-out;
  transition-property: all;
  outline: none;
  border: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  position: relative;
  height: 48px;
  color: #ffffff;
  background: #334155;
  cursor: pointer;
}
@media only screen and (max-width: 500px) {
  .clinic_login__button {
    font-size: 14px;
  }
}
.clinic_login__button:hover {
  background: #1e293b;
}
.clinic_login__button:focus {
  border-color: #334155;
  box-shadow: inset 0 0 0 2px #334155, inset 0 0 0 3px #ffffff;
  transition: box-shadow 20ms;
  outline: 1.5px dotted transparent;
}
.clinic_login__text {
  font-family: "sofia-pro", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  font-weight: 400;
  text-align: center;
  color: #475569;
  margin-top: 42px;
  padding-top: 16px;
}
@media only screen and (max-width: 500px) {
  .clinic_login__text {
    font-size: 14px;
  }
}
.clinic_login__link {
  font-family: "sofia-pro", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  font-weight: 500;
  color: #1e293b;
}
@media only screen and (max-width: 500px) {
  .clinic_login__link {
    font-size: 14px;
  }
}
.clinic_login__errors {
  margin-bottom: 24px;
  background: #fee2e2;
  border-radius: 3px;
  border: 1px solid #ef4444;
  padding: 12px 16px;
}
.clinic_login__error {
  font-family: "sofia-pro", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  font-weight: 500;
  color: #ef4444;
  margin-bottom: 4px;
  text-align: center;
}
@media only screen and (max-width: 500px) {
  .clinic_login__error {
    font-size: 14px;
  }
}
/**
  Resets any input to have 0 default styles
*/
.utils__error-background-placeholder {
  inset: 70% 0 0;
  position: absolute;
  z-index: 0;
  background-image: url("../img/pharmacy/backgrounds/lines-desktop.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (width <= 1440px) {
  .utils__error-background-placeholder {
    background-size: auto;
    background-position: top center;
  }
}
.store-disabled {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.store-disabled__content {
  position: relative;
  z-index: 1;
  padding: 24px;
  transform: translateY(-100px);
}
.store-disabled__image-container {
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 600px;
  margin: 0 auto;
}
.store-disabled__image {
  width: 100%;
  height: 100%;
}
.store-disabled__heading {
  font-family: "sofia-pro", sans-serif;
  font-size: 48px;
  line-height: 1.2;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 12px;
}
@media only screen and (max-width: 500px) {
  .store-disabled__heading {
    font-size: 30px;
  }
}
.store-disabled__description {
  font-family: "sofia-pro", sans-serif;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 400;
  color: #475569;
}
@media only screen and (max-width: 500px) {
  .store-disabled__description {
    font-size: 18px;
  }
}
.browser-upgrade__container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.browser-upgrade__content {
  z-index: 1;
  max-width: 800px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;
  padding: 16px 48px 24px;
}
.browser-upgrade__image {
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
}
.browser-upgrade__header {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.browser-upgrade__title {
  font-family: "sofia-pro", sans-serif;
  font-size: 36px;
  line-height: 1.2;
  font-weight: 600;
  color: #1e293b;
}
@media only screen and (max-width: 500px) {
  .browser-upgrade__title {
    font-size: 24px;
  }
}
.browser-upgrade__description {
  font-family: "sofia-pro", sans-serif;
  font-size: 18px;
  line-height: 1.56;
  font-weight: 400;
  color: #475569;
}
@media only screen and (max-width: 500px) {
  .browser-upgrade__description {
    font-size: 16px;
  }
}
.browser-upgrade__recommendations {
  display: grid;
  gap: 44px;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  margin: 24px 0;
}
@media only screen and (max-width: 500px) {
  .browser-upgrade__recommendations {
    grid-template-columns: repeat(2, 1fr);
  }
}
.browser-upgrade__icon {
  transition-duration: 20ms;
  transition-timing-function: ease-in-out;
  transition-property: filter;
  filter: drop-shadow(2px 2px 1px rgba(51, 65, 85, 0.1));
}
.browser-upgrade__recommendation {
  transition-duration: 20ms;
  transition-timing-function: ease-in-out;
  transition-property: transform;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}
.browser-upgrade__recommendation:hover {
  transform: scale(1.05);
}
.browser-upgrade__recommendation:hover .browser-upgrade__icon {
  filter: drop-shadow(4px 6px 2px rgba(51, 65, 85, 0.1));
}
.browser-upgrade__link {
  font-family: "sofia-pro", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  font-weight: 400;
  color: #475569;
}
@media only screen and (max-width: 500px) {
  .browser-upgrade__link {
    font-size: 14px;
  }
}
.browser-upgrade__footer {
  font-family: "sofia-pro", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: #94a3b8;
}
@media only screen and (max-width: 500px) {
  .browser-upgrade__footer {
    font-size: 14px;
  }
}
.system-error__container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.system-error__content {
  z-index: 1;
  max-width: 800px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;
  padding: 16px 48px 24px;
}
.system-error__image {
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 600px;
}
.system-error__title {
  font-family: "sofia-pro", sans-serif;
  font-size: 36px;
  line-height: 1.2;
  font-weight: 600;
  color: #1e293b;
}
@media only screen and (max-width: 500px) {
  .system-error__title {
    font-size: 24px;
  }
}
.system-error__description {
  font-family: "sofia-pro", sans-serif;
  font-size: 24px;
  line-height: 1.33;
  font-weight: 400;
  color: #1e293b;
}
@media only screen and (max-width: 500px) {
  .system-error__description {
    font-size: 20px;
  }
}
.system-error__next-steps {
  font-family: "sofia-pro", sans-serif;
  font-size: 18px;
  line-height: 1.56;
  font-weight: 400;
  color: #475569;
}
@media only screen and (max-width: 500px) {
  .system-error__next-steps {
    font-size: 16px;
  }
}
.system-error__link {
  transition-duration: 20ms;
  transition-timing-function: ease-in-out;
  transition-property: color;
  text-decoration: underline solid currentcolor;
}
.system-error__link:not(:disabled):hover,
.system-error__link:not(:disabled):focus-visible {
  color: #6366f1;
}
.system-error__link:not(:disabled):hover *,
.system-error__link:not(:disabled):focus-visible * {
  color: #6366f1;
}
.system-error__link:disabled {
  opacity: 0.4;
}
.system-error__link-button {
  font-family: "sofia-pro", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  transition-duration: 20ms;
  transition-timing-function: ease-in-out;
  transition-property: border-color, background, opacity;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #ffffff;
  background: #334155;
  padding: 12px 100px;
}
@media only screen and (max-width: 500px) {
  .system-error__link-button {
    font-size: 14px;
  }
}
.system-error__link-button:not(:disabled):hover {
  background: #1e293b;
}
.system-error__link-button:not(.isLoading):disabled {
  opacity: 0.4;
}
