@import "../../react/clinic/common/styles/variables.less";
@import "../../react/common/styles/imports.less";

.clinic_login {
  background: @page-primary-background;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 56px 24px;
  }

  &__lines {
    inset: 50% 0 0;
    position: absolute;
    z-index: 0;
    width: 100%;
    background-image: url("../img/pharmacy/backgrounds/lines-desktop.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__logo {
    width: 172px;
    margin-bottom: 24px;
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
  }

  &__box {
    border: @divider-border;
    border-radius: @border-radius[s];
    box-shadow: @box-shadow[200];
    background-color: @white;
    padding: 42px 60px;
    width: 100%;
    max-width: 540px;
  }

  &__title {
    .font(xl-2, semi-bold);

    color: @text-primary-color;
    text-align: center;
    margin-bottom: 32px;
  }

  &__input-group {
    margin-bottom: 24px;
    display: flex;
  }

  &__label-group {
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
  }

  &__label {
    .font(s, medium);

    color: @text-secondary-color;
  }

  &__help {
    .font(s, normal);

    color: @text-primary-color;
  }

  &__input {
    .font(m, normal);
    .transition-hover(border);

    color: @text-primary-color;
    display: block;
    width: 100%;
    padding: 12px 16px;
    border: @input-border;
    border-radius: @input-border-radius;
    background-color: @white;
    transition: border 0.1s ease-in-out;

    &:hover {
      border: @input-hover-border;
    }

    &:focus {
      .show-focus(border);
    }
  }

  &__button {
    .font(m, medium);
    .transition-hover(all);

    outline: none;
    border: none;
    border-radius: @action-border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    position: relative;
    height: 48px;
    color: @action-primary-text-color;
    background: @action-primary-background;
    cursor: pointer;

    &:hover {
      background: @action-primary-hover-background;
    }

    &:focus {
      .show-focus(primary);
    }
  }

  &__text {
    .font(s, normal);

    text-align: center;
    color: @text-secondary-color;
    margin-top: 42px;
    padding-top: 16px;
  }

  &__link {
    .font(s, medium);

    color: @text-primary-color;
  }

  &__errors {
    margin-bottom: 24px;
    background: @danger[100];
    border-radius: 3px;
    border: 1px solid @danger[500];
    padding: 12px 16px;
  }

  &__error {
    .font(s, medium);

    color: @danger[500];
    margin-bottom: 4px;
    text-align: center;
  }
}
